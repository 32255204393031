<!-- Hero Start -->
<section class="bg-half-170 bg-light d-table w-100" id="home">
  <div class="container">
    <div class="row mt-5 justify-content-center">
      <div class="col-lg-12 text-center">
        <div class="pages-heading">
          <h4 class="title mb-0"> About us </h4>
        </div>
      </div> <!--end col-->
    </div><!--end row-->

    <div class="position-breadcrumb">
      <nav aria-label="breadcrumb" class="d-inline-block">
        <ul class="breadcrumb rounded shadow mb-0 px-4 py-2">
          <li class="breadcrumb-item"><a routerLink="/index">Home</a></li>
          <li class="breadcrumb-item active" aria-current="page">About Us</li>
        </ul>
      </nav>
    </div>
  </div> <!--end container-->
</section><!--end section-->
<!-- Hero End -->
<!-- Shape Start -->
<div class="position-relative">
  <div class="shape overflow-hidden text-color-white">
    <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
    </svg>
  </div>
</div>
<!--Shape End-->


<section class="section">
  <div class="container">
    <div class="row align-items-center" id="counter">
      <div class="col-md-6 mb-4">
        <img src="assets/images/company/ab.jpg" class="img-fluid" alt="">
      </div>
      <!--end col-->

      <div class="col-md-6 mt-4 pt-2 mt-sm-0 pt-sm-0">
        <div class="ms-lg-4">
          <div class="d-flex mb-4">
            <span class="text-primary h1 mb-0"><span class="counter-value display-1 fw-bold" [countUp]="62"
                [options]="option"></span>+</span> <span class="h6 align-self-end ms-2">Years <br> Experience</span>
          </div>
          <div class="section-title">
            <h4 class="title mb-4">Celebrating Over 62 Years of Excellence</h4>
            <h5 class="mb-2">Our Legacy of Excellence :</h5>
            <p class="text-muted">Founded in 1963, <span class="text-primary fw-bold">Shree Sharda Kelavani
                Mandal</span> has been a beacon of educational excellence and community impact for over six decades.
              From humble beginnings, we have grown into a renowned institution known for nurturing young minds and
              shaping future leaders.</p>
            <h5 class="mb-2">Founders and Pioneers :</h5>
            <p class="text-muted">Founded under the visionary leadership of Mr. Ambubhai K. Patel, Mr. Harikrishna M.
              Patel, Mr. Kantibhai K. Patel, Shri Harshadbhai R. Shah, and Shri Girdharbhai P. Patel, our institution
              began with a small yet determined team dedicated to shaping young minds and building a brighter future.
            </p>
          </div>
        </div>
      </div>
      <!--end col-->
    </div>
    <div class="row align-items-center">
      <div class="col-md-6 mt-4 pt-2 mt-sm-0 pt-sm-0">
        <div class="ms-lg-4">
          <div class="section-title">
            <h5 class="mb-2">From Humble Beginnings to Remarkable Achievements :</h5>
            <p class="text-muted">Over the years, we have grown from a modest establishment to a renowned educational
              hub, thanks to the collective efforts of administrators, educators, students, and the support of our
              community. Through perseverance, innovation, and a focus on academic excellence, we have achieved numerous
              milestones and garnered accolades in the field of education.</p>
            <h5 class="mb-2">Community Collaboration and Impact :</h5>
            <p class="text-muted">The unwavering support of individuals, families, local organizations, and stakeholders
              has been instrumental in our success story. Their contributions, whether in the form of donations,
              infrastructure development, or mentorship, have helped us create a nurturing environment where students
              thrive academically, socially, and personally.
            </p>
            <h5 class="mb-2">A Vision for the Future :</h5>
            <p class="text-muted">As we look towards the future, our commitment to excellence, inclusivity, and
              community engagement remains steadfast. We aspire to continue making a positive impact in the lives of our
              students, empowering them to become responsible global citizens and leaders in their respective fields.
            </p>
          </div>
        </div>
      </div>
      <div class="col-md-6 mb-4">
        <img src="assets/images/company/ab2.jpg" class="img-fluid" alt="">
      </div>
    </div>
    <div class="d-flex mt-4">
      <ul ngbNav #nav="ngbNav" orientation="vertical" class="nav-pills rounded shadow p-3 mb-0 mt-0 col-md-3
          pt-2">
        <li ngbNavItem="top">
          <a ngbNavLink class="rounded">
            <div class="text-center pt-1 pb-1">
              <h6 class="mb-0">Vision</h6>
            </div>
          </a>
          <ng-template ngbNavContent>
            <div class="show active p-4 rounded shadow" id="developing" role="tabpanel"
              aria-labelledby="webdeveloping">
              <div class="mt-0">
                <h4 class="title mb-4">Inspiring Excellence, Shaping Futures
                </h4>
                <p class="text-muted">Our vision is to inspire excellence and shape futures by providing a
                  transformative educational experience. We envision a world where every individual has the
                  opportunity to fulfill their potential and contribute meaningfully to society.</p>
              </div>
            </div>
          </ng-template>
        </li>
        <li ngbNavItem>
          <a ngbNavLink class="rounded">
            <div class="text-center pt-1 pb-1">
              <h6 class="mb-0">Mission</h6>
            </div>
          </a>
          <ng-template ngbNavContent>
            <div class="p-4 rounded shadow" id="data-analise" role="tabpanel" aria-labelledby="database">
              <div class="mt-0">
                <h4 class="title mb-4">Empowering Minds, Enriching Lives</h4>
                <p class="text-muted">Our mission is to empower minds and enrich lives through quality education,
                  holistic development, and community engagement. We are dedicated to instilling values of integrity,
                  excellence, diversity, and collaboration in our students, preparing them for success in a rapidly
                  changing world.</p>
              </div>
            </div>
            <!--end teb pane-->
          </ng-template>
        </li>
        <li ngbNavItem>
          <a ngbNavLink class="rounded">
            <div class="text-center pt-1 pb-1">
              <h6 class="mb-0">Value</h6>
            </div>
          </a>
          <ng-template ngbNavContent>
            <div class="p-4 rounded shadow" id="security" role="tabpanel" aria-labelledby="server">
              <div class="mt-0">
                <h4 class="title mb-4">Our Values</h4>
                <ul class="list-unstyled text-muted">
                  <li class="mb-1"><span class="text-primary h6 me-2">Excellence : </span>We strive for excellence in
                    all aspects of education and operations.
                  </li>
                  <li class="mb-1"><span class="text-primary h6 me-2">Integrity : </span>We uphold the highest ethical
                    standards and accountability.
                  </li>
                  <li class="mb-1"><span class="text-primary h6 me-2">Diversity and Inclusivity : </span> We celebrate
                    diversity and create an inclusive environment for all.
                  </li>
                  <li class="mb-1"><span class="text-primary h6 me-2">Collaboration : </span>We value teamwork and
                    partnership for collective success.
                  </li>
                  <li class="mb-1"><span class="text-primary h6 me-2">Diversity and Inclusivity : </span> We celebrate
                    diversity and create an inclusive environment for all.
                  </li>
                  <li class="mb-1"><span class="text-primary h6 me-2">Community Engagement : </span>We actively engage
                    with our community and contribute positively to societal development.
                  </li>
                  <li class="mb-1"><span class="text-primary h6 me-2">Respect : </span>We respect the dignity and
                    uniqueness of every individual.
                  </li>
                  <li class="mb-1"><span class="text-primary h6 me-2">Responsibility : </span>We take responsibility
                    for our actions and their impact.
                  </li>
                  <li class="mb-1"><span class="text-primary h6 me-2">Lifelong Learning : </span>We promote a culture
                    of continuous learning and growth.
                  </li>
                </ul>
              </div>
            </div>
            <!--end teb pane-->
          </ng-template>
        </li>
        <li ngbNavItem>
          <a ngbNavLink class="rounded">
            <div class="text-center pt-1 pb-1">
              <h6 class="mb-0">Achievements</h6>
            </div>
          </a>
          <ng-template ngbNavContent>
            <div class="p-4 rounded shadow" id="data-analise" role="tabpanel" aria-labelledby="database">
              <div class="mt-0">
                <h4 class="title mb-4">Our Achievements</h4>
                <p class="text-muted">Over the years, we have achieved numerous milestones and garnered recognition
                  for our commitment to educational excellence, innovative programs, and community outreach
                  initiatives. Our alumni are leaders in various fields, making a positive impact globally.</p>
              </div>
            </div>
            <!--end teb pane-->
          </ng-template>
        </li>
      </ul>
      <div [ngbNavOutlet]="nav" class="mt-0 ms-4"></div>
    </div>
    <!--end row-->
  </div>

  <!-- FAQ n Contact End -->
  <a href="javascript: void(0);" class="btn btn-icon btn-primary back-to-top" id="back-to-top" [ngxScrollTo]="'#home'">
    <i-feather name="arrow-up" class="icons"></i-feather>
  </a>
  <!-- Back to top -->