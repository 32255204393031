<section class="bg-half-100 d-table w-100" id="home">
  <ngb-carousel [showNavigationArrows]="true" [showNavigationIndicators]="true" [pauseOnHover]="true" [keyboard]="true"
    [wrap]="true" [interval]="4000" [pause]="'hover'" [noWrap]="false" [animation]="'slide'" [pauseOnFocus]="true">
    <ng-template ngbSlide *ngFor="let c of sliderImages;let i=index">
      <div class="picsum-img-wrapper">
        <img src="https://api.shreeshardakelavanimandal.ac.in{{c.image}}" class="d-block w-100" alt="...">
      </div>
    </ng-template>
  </ngb-carousel>
</section>
<app-notification></app-notification>
<div class="container z-index-1">
  <div class="row align-items-center mb-5">
    <div class="col-lg-6 col-md-6">
      <div class="section-title">
        <div class="title-heading">
          <p class="para-desc text-muted">Welcome to <span class="text-primary">The Parade of Institutions</span></p>
          <h1 class="heading mb-3">Celebrating Over 62 Years of Excellence</h1>
          <h5 class="text-primary">
            "God will help provided that the six qualities of Effort, Adventure, Courage, Knowledge, Strength, and
            Bravery exist."</h5>
          <p class="para-desc text-muted">Founded in 1963, <span class="text-primary">Shree Sharda Kelavani
              Mandal</span> has been a beacon of educational excellence and community impact for over six decades. From
            humble beginnings, we have grown into a renowned institution known for nurturing young minds and shaping
            future leaders.</p>

          <div class="mt-4">
            <a routerLink="/about-us" class="btn btn-primary mt-2 me-2">View More <i-feather name="chevron-right"
                class="fea icon-sm"></i-feather>
            </a>
          </div>
        </div>
      </div>
    </div><!--end col-->

    <div class="col-lg-6 col-md-6 mt-4 pt-2 mt-sm-0 pt-sm-0">
      <img src="assets/images/gate.jpg" class="img-fluid" alt="">
    </div><!--end col-->
  </div><!--end row-->
</div><!--end container-->
<section class="section bg-light">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-12">
        <div class="section-title text-center mb-4 pb-2">
          <h4 class="title ">Get a choice of your <span class="text-primary">Institute</span></h4>
          <p class="text-muted para-desc mx-auto mb-0">Empowering minds through quality education and transformative
            learning experiences.</p>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
    <div class="row">
      <div class="col-12 mt-4 pt-2">
        <owl-carousel-o id="customer-testi" [options]="customOptions">
          <ng-template carouselSlide>
            <div class="card customer-testi features feature-primary explore-feature text-center border rounded mx-2">
              <div class="card-body">
                <img src="assets/images/icon/preschool.png" (click)="openInstituteById('Pre-School')"
                  class="img-fluid avatar avatar-ex-large rounded-circle shadow mx-auto" alt="school">
                <a (click)="openInstituteById('Pre-School')">
                  <h5><a class="title text-dark">Pre
                      School (Balmandir)</a></h5>
                  <h6 class="text-primary">Shree Manhar Shishu Vihar
                    (Balmandir)
                  </h6>
                </a>
              </div>
            </div>
          </ng-template>
          <ng-template carouselSlide>
            <div class="card customer-testi features feature-primary explore-feature text-center border rounded mx-2">
              <div class="card-body">
                <img src="assets/images/icon/primary.png"
                  class="img-fluid avatar avatar-ex-large rounded-circle shadow mx-auto" alt=""
                  (click)="openInstituteById('Primary')">
                <a (click)="openInstituteById('Primary')">
                  <h5><a class="title text-dark">Primary
                      School</a></h5>
                  <h6 class="text-primary">Shree Ambalal Chotalal Sharda Mandir</h6>
                </a>
              </div>
            </div>
          </ng-template>
          <ng-template carouselSlide>
            <div class="card customer-testi features feature-primary explore-feature text-center border rounded mx-2">
              <div class="card-body">
                <img src="assets/images/icon/secondary.png"
                  class="img-fluid avatar avatar-ex-large rounded-circle shadow mx-auto" alt="school"
                  (click)="openInstituteById('Secondary')">
                <a (click)="openInstituteById('Secondary')">
                  <h5><a class="title text-dark">Secondary School</a></h5>
                  <h6 class="text-primary">Shree Laxmichand Mithalal Sharda Mandir</h6>
                </a>
              </div>
            </div>
          </ng-template>
          <ng-template carouselSlide>
            <div class="card customer-testi features feature-primary explore-feature text-center border rounded mx-2">
              <div class="card-body">
                <img src="assets/images/icon/multimedia.png"
                  class="img-fluid avatar avatar-ex-large rounded-circle shadow mx-auto" alt=""
                  (click)="openInstituteById('Multimedia-Balmandir')">
                <a (click)="openInstituteById('Multimedia-Balmandir')">
                  <h5><a class="title text-dark">Multimedia Balmandir</a></h5>
                  <h6 class="text-primary">Shri R.R. Dave Multimedia Education Center</h6>
                </a>
              </div>
            </div>
          </ng-template>
          <ng-template carouselSlide>
            <div class="card customer-testi features feature-primary explore-feature text-center border rounded mx-2">
              <div class="card-body">
                <img src="assets/images/icon/computer.png"
                  class="img-fluid avatar avatar-ex-large rounded-circle shadow mx-auto" alt=""
                  (click)="openInstituteById('Computer-Education')">
                <a (click)="openInstituteById('Computer-Education')">
                  <h5><a class="title text-dark">Computer Education</a></h5>
                  <h6 class="text-primary">Shri Kanchanlal Manilal Shah Chahwala Computer Education Centre</h6>
                </a>
              </div>
            </div>
          </ng-template>
          <ng-template carouselSlide>
            <div class="card customer-testi features feature-primary explore-feature text-center border rounded mx-2">
              <div class="card-body">
                <img src="assets/images/icon/computer1.png"
                  class="img-fluid avatar avatar-ex-large rounded-circle shadow mx-auto" alt=""
                  (click)="openInstituteById('Sharda-Multimedia')">
                <a (click)="openInstituteById('Sharda-Multimedia')">
                  <h5><a class="title text-dark">Multimedia</a></h5>
                  <h6 class="text-primary">Shree Sharda Kelavani Mandal Multimedia Education Centre
                  </h6>
                </a>
              </div>
            </div>
          </ng-template>
        </owl-carousel-o>
      </div>
      <!--end col-->
    </div>
  </div>
</section>
<section class="section">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-12 text-center">
        <div class="section-title mb-4 pb-2">
          <h4 class="title mb-4"><span class="text-primary">Milestones and Achievements</span></h4>
          <p class="text-muted para-desc mx-auto mb-0"><span class="text-primary fw-bold">Shree Sharda Kelavani
              Mandal</span>: Celebrating Academic Excellence Leading the way in education with innovative programs and
            dedicated faculty.</p>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->

    <div class="row" id="counter">
      <div class="col-md-3 col-6 mt-4 pt-2">
        <div class="counter-box text-center">
          <img src="assets/images/schools-logo/clock.png" class="avatar avatar-small" alt="">
          <h2 class="mb-0 mt-4"><span class="counter-value" [countUp]="62"></span>+</h2>
          <h6 class="counter-head text-muted">Years of Education</h6>
        </div>
        <!--end counter box-->
      </div>

      <div class="col-md-3 col-6 mt-4 pt-2">
        <div class="counter-box text-center">
          <img src="assets/images/schools-logo/award.png" class="avatar avatar-small" alt="">
          <h2 class="mb-0 mt-4">25+</h2>
          <h6 class="counter-head text-muted">Awards</h6>
        </div>
        <!--end counter box-->
      </div>

      <div class="col-md-3 col-6 mt-4 pt-2">
        <div class="counter-box text-center">
          <img src="assets/images/schools-logo/student.png" class="avatar avatar-small" alt="">
          <h2 class="mb-0 mt-4"><span class="counter-value" [countUp]="1.2" [options]="option"></span>M</h2>
          <h6 class="counter-head text-muted">Total Students Since 1963-Present</h6>
        </div>
        <!--end counter box-->
      </div>

      <div class="col-md-3 col-6 mt-4 pt-2">
        <div class="counter-box text-center">
          <img src="assets/images/schools-logo/satisfaction.png" class="avatar avatar-small" alt="">
          <h2 class="mb-0 mt-4"><span class="counter-value" [countUp]="99.99" [options]="option"></span>%</h2>
          <h6 class="counter-head text-muted">Satisfaction Rate</h6>
        </div>
        <!--end counter box-->
      </div>
    </div>
    <!--end row-->
  </div>
</section>
<section class="section bg-light">
  <div class="container">
    <div class="row align-items-center">
      <div class="col-lg-6 col-md-6 mt-4 mt-sm-0 pt-2 pt-sm-0 text-center"
        *ngIf="instituteUrl=='www.lm.shreeshardakelavanimandal.ac.in'">
        <iframe
          src="https://www.facebook.com/shreeshardakelavanimandal/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2Fprofile.php%3Fid%3D100063660504356&tabs=timeline&width=340&height=500&small_header=true&adapt_container_width=true&hide_cover=false&show_facepile=true&appId"
          width="340" height="500" style="border:none;overflow:hidden" scrolling="no" frameborder="0"
          allowfullscreen="true"
          allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe>
      </div>
      <div class="col-lg-6 col-md-6 mt-4 mt-sm-0 pt-2 pt-sm-0 text-center"
        *ngIf="instituteUrl!='www.lm.shreeshardakelavanimandal.ac.in'">
        <iframe
          src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2Fshreeshardakelavanimandal&tabs=timeline&width=500&height=700&small_header=true&adapt_container_width=true&hide_cover=false&show_facepile=true&appId=381676861696284"
          width="500" height="700" style="border:none;overflow:hidden" scrolling="no" frameborder="0"
          allowfullscreen="true"
          allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe>
      </div>
      <!--end col-->

      <div class="col-lg-6 col-md-6 order-1 order-md-2">
        <div class="row justify-content-center">
          <div class="col-12 text-center">
            <div class="section-title mb-1 pb-1">
              <h4 class="title mb-2"><span class="text-primary">News & Events</span></h4>
            </div>
          </div>
          <!--end col-->
        </div>
        <div class="card event-schedule event-primary rounded border" *ngIf="newsData.length==0">
          <div class="card-body text-center">
            <span class="text-center text-warning">News & Events not available.</span>
          </div>
        </div>
        <div *ngIf="newsData.length>0">
          <div class="card event-schedule event-primary rounded border mt-2" *ngFor="let n of newsData">
            <div class="card-body">
              <div class="d-flex">
                <ul class="date text-center me-3 mb-0 list-unstyled">
                  <li class="day fw-bold">
                    <i-feather name="message-circle"></i-feather>
                  </li>
                </ul>
                <div class="flex-1 content">
                  <!-- <h5><a href="javascript:void(0)" class="text-dark title">Digital Conference Event
                            Intro</a>
                    </h5> -->
                  <span class="text-dark">{{n.date |date}}</span>
                  <p class="text-muted location-time" [innerHTML]="n.message"></p>
                  <a href="https://api.shreeshardakelavanimandal.ac.in{{n.files}}" target="_blank"
                    *ngIf="n.files!='null'" class="btn btn-soft-primary btn-sm">View <i
                      class="mdi mdi-arrow-right"></i></a>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 mb-10">
            <div class="text-center mt-2">
              <a routerLink="/news" class="btn btn-primary">See More
                <i-feather name="chevron-right" class="fea icon-sm"></i-feather>
              </a>
            </div>
          </div>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
</section>

<!-- <section class="section border-bottom">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-12 text-center">
        <div class="section-title mb-4 pb-2">
          <h4 class="title mb-4">How It Work ?</h4>
          <p class="text-muted para-desc mb-0 mx-auto">Start working with <span
              class="text-primary fw-bold">Landrick</span> that can provide everything you need to generate awareness,
            drive traffic, connect.</p>
        </div>
      </div>
    </div>

    <div class="row align-items-center">
      <div class="col-lg-5 col-md-6 mt-4 pt-2">
        <img src="assets/images/illustrator/SEO_SVG.svg" alt="">
      </div>

      <div class="col-lg-7 col-md-6 mt-4 pt-2">
        <div class="section-title ms-lg-5">
          <h4 class="title mb-4">Change the way you build websites</h4>
          <p class="text-muted">You can combine all the Landrick templates into a single one, you can take a component
            from the Application theme and use it in the Website.</p>
          <ul class="list-unstyled text-muted">
            <li class="mb-1"><span class="text-primary h5 me-2"><i
                  class="uil uil-check-circle align-middle"></i></span>Digital Marketing Solutions for Tomorrow</li>
            <li class="mb-1"><span class="text-primary h5 me-2"><i
                  class="uil uil-check-circle align-middle"></i></span>Our Talented & Experienced Marketing Agency</li>
            <li class="mb-1"><span class="text-primary h5 me-2"><i
                  class="uil uil-check-circle align-middle"></i></span>Create your own skin to match your brand</li>
          </ul>
          <a href="javascript:void(0)" class="mt-3 h6 text-primary">Find Out More <i
              class="uil uil-angle-right-b align-middle"></i></a>
        </div>
      </div>
    </div>
  </div>
</section> -->


<div class="position-relative">
  <div class="shape overflow-hidden text-footer">
    <svg viewBox="0 0 2880 250" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M720 125L2160 0H2880V250H0V125H720Z" fill="currentColor"></path>
    </svg>
  </div>
</div>
<!-- FAQ n Contact End -->


<!-- Back to top -->
<a href="javascript:void(0)" [ngxScrollTo]="'#home'" id="back-to-top" class="btn btn-icon btn-primary back-to-top">
  <i-feather name="arrow-up" class="icons"></i-feather>
</a>
<!-- Back to top -->