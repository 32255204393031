<!-- Hero Start -->
<section class="bg-half-170 bg-light d-table w-100" id="home">
    <div class="container">
        <div class="row mt-5 justify-content-center">
            <div class="col-lg-12 text-center">
                <div class="pages-heading">
                    <h4 class="title mb-0"> Blog </h4>
                </div>
            </div> <!--end col-->
        </div><!--end row-->

        <div class="position-breadcrumb">
            <nav aria-label="breadcrumb" class="d-inline-block">
                <ul class="breadcrumb rounded shadow mb-0 px-4 py-2">
                    <li class="breadcrumb-item"><a routerLink="/index">Home</a></li>
                    <li class="breadcrumb-item active" aria-current="page">Blog</li>
                </ul>
            </nav>
        </div>
    </div> <!--end container-->
</section><!--end section-->
<!-- Hero End -->

<!-- Shape Start -->
<div class="position-relative">
    <div class="shape overflow-hidden text-color-white">
        <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
        </svg>
    </div>
</div>
<!--Shape End-->

<!-- Blog STart -->
<section class="section">
    <div class="container">
        <div class="card event-schedule event-primary rounded border mb-4" *ngIf="blogsData.length==0">
            <div class="card-body text-center">
                <span class="text-center text-warning">Blogs are not available.</span>
            </div>
        </div>
        <div class="row" *ngIf="blogsData.length>0">

            <div class="row">
                <div class="col-lg-4 col-md-6 mb-4 pb-2" *ngFor="let b of paginatedBlogsData">
                    <div class="card blog rounded border-0 shadow overflow-hidden">
                        <div class="position-relative">
                            <img [src]="'https://api.shreeshardakelavanimandal.ac.in' + b.blogImage" class="card-img-top" alt="...">
                            <div class="overlay rounded-top bg-dark"></div>
                        </div>
                        <div class="card-body content">
                            <h5><a routerLink="" (click)="continueBlog(b.id)" class="card-title title text-dark">{{
                                    b.blogTitle }}</a>
                            </h5>
                            <div class="post-meta d-flex justify-content-between mt-3">
                                <a routerLink="" (click)="continueBlog(b.id)" class=" text-muted readmore">Read More <i
                                        class="uil uil-angle-right-b align-middle"></i></a>
                            </div>
                        </div>
                        <div class="author">
                            <small class="text-light user d-block"><i class="uil uil-user"></i> {{ b.authorName
                                }}</small>
                            <small class="text-light date"><i class="uil uil-calendar-alt"></i> {{ b.blogDate | date
                                }}</small>
                        </div>
                    </div>
                </div>
            </div>
            <!--end row-->

            <!-- PAGINATION START -->
            <div class="col-12">
                <ul class="pagination justify-content-center mb-0">
                    <li class="page-item" [class.disabled]="currentPage === 1">
                        <a class="page-link" href="javascript:void(0)" aria-label="Previous"
                            (click)="prevPage()">Prev</a>
                    </li>
                    <li class="page-item" *ngFor="let page of pages" [class.active]="currentPage === page">
                        <a class="page-link" href="javascript:void(0)" (click)="changePage(page)">{{ page }}</a>
                    </li>
                    <li class="page-item" [class.disabled]="currentPage === totalPages">
                        <a class="page-link" href="javascript:void(0)" aria-label="Next" (click)="nextPage()">Next</a>
                    </li>
                </ul>
            </div>

            <!--end col-->
            <!-- PAGINATION END -->
        </div>
        <!--end row-->
    </div>
    <!--end container-->
</section>
<!--end section-->
<!-- Blog End -->
<div class="position-relative">
    <div class="shape overflow-hidden text-footer">
        <svg viewBox="0 0 2880 250" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M720 125L2160 0H2880V250H0V125H720Z" fill="currentColor"></path>
        </svg>
    </div>
</div>
<!-- Back to top -->
<a href="javascript: void(0);" class="btn btn-icon btn-primary back-to-top" id="back-to-top" [ngxScrollTo]="'#home'">
    <i-feather name="arrow-up" class="icons"></i-feather>
</a>
<!-- Back to top -->