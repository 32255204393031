<!-- Hero Start -->
<section class="bg-half-170 bg-light d-table w-100" id="home">
    <div class="container">
        <div class="row mt-5 justify-content-center">
            <div class="col-lg-12 text-center">
                <div class="pages-heading">
                    <h4 class="title mb-0" *ngIf="openForms">Forms</h4>
                    <h4 class="title mb-0" *ngIf="openSyllabus">Syllabus</h4>
                    <h4 class="title mb-0" *ngIf="openReport">Reports</h4>

                </div>
            </div> <!--end col-->
        </div><!--end row-->

        <div class="position-breadcrumb">
            <nav aria-label="breadcrumb" class="d-inline-block">
                <ul class="breadcrumb rounded shadow mb-0 px-4 py-2">
                    <li class="breadcrumb-item"><a routerLink="/index">Home</a></li>
                    <li class="breadcrumb-item active" aria-current="page" *ngIf="openForms">Forms</li>
                    <li class="breadcrumb-item active" aria-current="page" *ngIf="openSyllabus">Syllabus</li>
                    <li class="breadcrumb-item active" aria-current="page" *ngIf="openReport">Reports</li>
                </ul>
            </nav>
        </div>
    </div> <!--end container-->
</section><!--end section-->
<!-- Hero End -->
<!-- Shape Start -->
<div class="position-relative">
    <div class="shape overflow-hidden text-color-white">
        <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
        </svg>
    </div>
</div>
<!--Shape End-->
<!-- Start -->
<section class="section mb-5">
    <div class="container">
        <!-- <div class="row justify-content-center">
            <div class="col-12 text-center">
                <div class="section-title mb-4 pb-2">
                    <h4 class="title mb-4">See everything about your <span class="text-primary">Landrick</span></h4>
                    <p class="text-muted para-desc mx-auto mb-0">Start working with <span
                            class="text-primary fw-bold">Landrick</span> that can provide everything you need to
                        generate awareness,
                        drive traffic, connect.</p>
                </div>
            </div>
        </div> -->
        <div class="row" *ngIf="openSyllabus">
            <div class="col-lg-12 col-md-12 col-12">
                <div class="card event-schedule event-primary rounded border" *ngIf="syllabusData.length==0">
                    <div class="card-body text-center">
                        <span class="text-center text-warning">Syllabus not available.</span>
                    </div>
                </div>
                <div class="row" *ngIf="syllabusData.length>0">
                    <div class="col-lg-4 col-md-6 mt-4 pt-2" *ngFor="let c of syllabusData ;let i=index">
                        <a href="https://api.shreeshardakelavanimandal.ac.in{{c.files}}" target="_blank">
                            <div
                                class="d-flex features feature-primary key-feature align-items-center p-3 rounded shadow">
                                <div class="icon text-center rounded-circle me-3">
                                    <i-feather name="file-text" class="fea icon-ex-md"></i-feather>
                                </div>
                                <div class="flex-1">
                                    <h4 class="title mb-0">{{c.title}}</h4>
                                    <!-- <p class="text-muted mb-0">London, UK</p> -->
                                </div>
                            </div>
                        </a>
                    </div>
                    <!--end col-->
                </div>
            </div>
        </div>
        <div class="row" *ngIf="openForms">
            <div class="col-lg-12 col-md-12 col-12">
                <div class="card event-schedule event-primary rounded border" *ngIf="formsData.length==0">
                    <div class="card-body text-center">
                        <span class="text-center text-warning">Forms not available.</span>
                    </div>
                </div>
                <div class="row" *ngIf="formsData.length>0">
                    <div class="col-lg-4 col-md-6 mt-4 pt-2" *ngFor="let c of formsData ;let i=index">
                        <a href="https://api.shreeshardakelavanimandal.ac.in{{c.files}}" target="_blank">
                            <div
                                class="d-flex features feature-primary key-feature align-items-center p-3 rounded shadow">
                                <div class="icon text-center rounded-circle me-3">
                                    <i-feather name="file-text" class="fea icon-ex-md"></i-feather>
                                </div>
                                <div class="flex-1">
                                    <h4 class="title mb-0">{{c.title}}</h4>
                                    <!-- <p class="text-muted mb-0">London, UK</p> -->
                                </div>
                            </div>
                        </a>
                    </div>
                    <!--end col-->
                </div>
            </div>
        </div>
        <div class="row" *ngIf="openReport">
            <div class="col-lg-12 col-md-12 col-12">
                <div class="card event-schedule event-primary rounded border" *ngIf="reportData.length==0">
                    <div class="card-body text-center">
                        <span class="text-center text-warning">Reports not available.</span>
                    </div>
                </div>
                <div class="row" *ngIf="reportData.length>0">
                    <div class="col-lg-4 col-md-6 mt-4 pt-2" *ngFor="let c of reportData ;let i=index">
                        <a href="https://api.shreeshardakelavanimandal.ac.in{{c.files}}" target="_blank">
                            <div
                                class="d-flex features feature-primary key-feature align-items-center p-3 rounded shadow">
                                <div class="icon text-center rounded-circle me-3">
                                    <i-feather name="file-text" class="fea icon-ex-md"></i-feather>
                                </div>
                                <div class="flex-1">
                                    <h4 class="title mb-0">{{c.title}}</h4>
                                    <!-- <p class="text-muted mb-0">London, UK</p> -->
                                </div>
                            </div>
                        </a>
                    </div>
                    <!--end col-->
                </div>
            </div>
        </div>
    </div>
</section>

<div class="position-relative">
    <div class="shape overflow-hidden text-footer">
        <svg viewBox="0 0 2880 250" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M720 125L2160 0H2880V250H0V125H720Z" fill="currentColor"></path>
        </svg>
    </div>
</div>
<!-- Back to top -->
<a href="javascript: void(0);" class="btn btn-icon btn-primary back-to-top" id="back-to-top" [ngxScrollTo]="'#home'">
    <i-feather name="arrow-up" class="icons"></i-feather>
</a>