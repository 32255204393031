<!-- Start -->
<section class="section">
    <!-- About Start -->
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-6">
                <div class="row align-items-center">
                    <img src="assets/images/multimedia/l1.jpg" class="img-fluid" alt="work-image">

                </div>
                <!--end row-->
            </div>
            <!--end col-->

            <div class="col-lg-6 col-md-6 mt-4 mt-lg-0 pt- pt-lg-0">
                <div class="ms-lg-4">
                    <div class="section-title mb-4 pb-2">
                        <h4 class="title mb-4">{{multiData.name}}</h4>
                        <h5 class="text-primary fw-bold">{{multiData.title}}</h5>
                        <p class="para-desc mb-0">{{multiData.details}}</p>
                        <div>
                            <h5 class="text-primary fw-bold">Our Services:</h5>
                            <ul>
                                <li><span class="text-primary fw-bold">Interactive Learning Modules:</span> We offer
                                    interactive
                                    multimedia learning modules
                                    designed specifically for pre-school students, focusing on creative expression,
                                    storytelling, basic digital skills, and early technology exploration.</li>
                                <li><span class="text-primary fw-bold">Multimedia Storytelling:</span>Through
                                    multimedia storytelling activities, students learn to express their ideas, develop
                                    storytelling skills, and enhance their digital literacy through age-appropriate
                                    tools and activities.</li>
                                <li><span class="text-primary fw-bold">Digital Art and Animation: </span>Our center
                                    introduces young learners to digital art and animation concepts, allowing them to
                                    unleash their imagination and create their own digital artworks and simple
                                    animations.</li>
                                <li><span class="text-primary fw-bold">Playful Learning Environment: </span>We believe
                                    in learning through play, and our center provides a playful and safe environment
                                    where students can experiment, explore, and learn through hands-on activities and
                                    interactive games.</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!--end row-->
    </div>
    <!--end container-->

    <!-- <div class="container mt-100 mt-60">
        <div class="row">
            <div class="col-lg-3 col-md-6 col-12">
                <div class="d-flex features feature-clean feature-primary">
                    <div class="icons text-primary text-center mx-auto">
                        <i class="uil uil-airplay d-block rounded h3 mb-0"></i>
                    </div>
                    <div class="flex-1 content ms-3">
                        <h5 class="mb-1"><a href="javascript:void(0)" class="text-dark">Learners</a></h5>
                        <p class="text-muted mb-0">This is required when, for text is not yet available.</p>
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-md-6 col-12 mt-4 pt-2 mt-sm-0 pt-sm-0">
                <div class="d-flex features feature-clean feature-primary">
                    <div class="icons text-primary text-center mx-auto">
                        <i class="uil uil-bag d-block rounded h3 mb-0"></i>
                    </div>
                    <div class="flex-1 content ms-3">
                        <h5 class="mb-1"><a href="javascript:void(0)" class="text-dark">Teachers</a></h5>
                        <p class="text-muted mb-0">This is required when, for text is not yet available.</p>
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-md-6 col-12 mt-4 pt-2 mt-lg-0 pt-lg-0">
                <div class="d-flex features feature-clean feature-primary">
                    <div class="icons text-primary text-center mx-auto">
                        <i class="uil uil-star d-block rounded h3 mb-0"></i>
                    </div>
                    <div class="flex-1 content ms-3">
                        <h5 class="mb-1"><a href="javascript:void(0)" class="text-dark">Parents</a></h5>
                        <p class="text-muted mb-0">This is required when, for text is not yet available.</p>
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-md-6 col-12 mt-4 pt-2 mt-lg-0 pt-lg-0">
                <div class="d-flex features feature-clean feature-primary">
                    <div class="icons text-primary text-center mx-auto">
                        <i class="uil uil-at d-block rounded h3 mb-0"></i>
                    </div>
                    <div class="flex-1 content ms-3">
                        <h5 class="mb-1"><a href="javascript:void(0)" class="text-dark">Doners</a></h5>
                        <p class="text-muted mb-0">This is required when, for text is not yet available.</p>
                    </div>
                </div>
            </div>
        </div>
    </div> -->
    <!--end container-->
    <!-- About End -->

    <!-- Teachers Start -->
    <!-- <div class="container mt-100 mt-60">
        <div class="row justify-content-center">
            <div class="col-12">
                <div class="section-title text-center mb-4 pb-2">
                    <h4 class="title mb-4">Our Instructor</h4>
                    <p class="text-muted para-desc mb-0 mx-auto">Start working with <span
                            class="text-primary fw-bold">Landrick</span> that can provide everything you need to
                        generate awareness,
                        drive traffic, connect.</p>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-lg-4 col-md-6 col-12 mt-4 pt-2">
                <div class="d-flex align-items-center">
                    <img src="assets/images/client/05.jpg" class="avatar avatar-medium rounded-circle img-thumbnail"
                        alt="">
                    <div class="flex-1 content ms-3">
                        <h5 class="mb-0"><a href="javascript:void(0)" class="text-dark">Krista John</a></h5>
                        <small class="position text-muted">Professor</small>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-12 mt-4 pt-2">
                <div class="d-flex align-items-center">
                    <img src="assets/images/client/06.jpg" class="avatar avatar-medium rounded-circle img-thumbnail"
                        alt="">
                    <div class="flex-1 content ms-3">
                        <h5 class="mb-0"><a href="javascript:void(0)" class="text-dark">Jack John</a></h5>
                        <small class="position text-muted">Professor</small>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-12 mt-4 pt-2">
                <div class="d-flex align-items-center">
                    <img src="assets/images/client/01.jpg" class="avatar avatar-medium rounded-circle img-thumbnail"
                        alt="">
                    <div class="flex-1 content ms-3">
                        <h5 class="mb-0"><a href="javascript:void(0)" class="text-dark">Roger Jackson</a></h5>
                        <small class="position text-muted">Professor</small>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-12 mt-4 pt-2">
                <div class="d-flex align-items-center">
                    <img src="assets/images/client/02.jpg" class="avatar avatar-medium rounded-circle img-thumbnail"
                        alt="">
                    <div class="flex-1 content ms-3">
                        <h5 class="mb-0"><a href="javascript:void(0)" class="text-dark">Luchhi Cina</a></h5>
                        <small class="position text-muted">Professor</small>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-12 mt-4 pt-2">
                <div class="d-flex align-items-center">
                    <img src="assets/images/client/03.jpg" class="avatar avatar-medium rounded-circle img-thumbnail"
                        alt="">
                    <div class="flex-1 content ms-3">
                        <h5 class="mb-0"><a href="javascript:void(0)" class="text-dark">Sophiya Cally</a></h5>
                        <small class="position text-muted">Professor</small>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-12 mt-4 pt-2">
                <div class="d-flex align-items-center">
                    <img src="assets/images/client/04.jpg" class="avatar avatar-medium rounded-circle img-thumbnail"
                        alt="">
                    <div class="flex-1 content ms-3">
                        <h5 class="mb-0"><a href="javascript:void(0)" class="text-dark">Johnny English</a></h5>
                        <small class="position text-muted">Professor</small>
                    </div>
                </div>
            </div>
        </div>
    </div> -->
    <!-- Teachers End -->

</section>
<!--end section-->
<!-- End -->
<div class="position-relative">
    <div class="shape overflow-hidden text-footer">
        <svg viewBox="0 0 2880 250" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M720 125L2160 0H2880V250H0V125H720Z" fill="currentColor"></path>
        </svg>
    </div>
</div>
<!-- Back to top -->
<a href="javascript: void(0);" class="btn btn-icon btn-primary back-to-top" id="back-to-top" [ngxScrollTo]="'#home'">
    <i-feather name="arrow-up" class="icons"></i-feather>
</a>
<!-- Back to top -->