<!-- Hero Start -->
<section class="bg-half-170 bg-light d-table w-100" id="home">
    <div class="container">
        <div class="row mt-5 justify-content-center">
            <div class="col-lg-12 text-center">
                <div class="pages-heading">
                    <h4 class="title mb-0"> Academic </h4>
                </div>
            </div> <!--end col-->
        </div><!--end row-->

        <div class="position-breadcrumb">
            <nav aria-label="breadcrumb" class="d-inline-block">
                <ul class="breadcrumb rounded shadow mb-0 px-4 py-2">
                    <li class="breadcrumb-item"><a routerLink="/index">Home</a></li>
                    <li class="breadcrumb-item active" aria-current="page">Academic</li>
                </ul>
            </nav>
        </div>
    </div> <!--end container-->
</section><!--end section-->
<!-- Hero End -->
<!-- Shape Start -->
<div class="position-relative">
    <div class="shape overflow-hidden text-color-white">
        <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
        </svg>
    </div>
</div>
<!--Shape End-->
<!-- Start -->
<section class="section mb-5">
    <div class="container">
        <div class="row">
            <div class="tab-content" id="pills-tabContent">
                <div class="tab-pane fade show active" id="pills-buying" role="tabpanel"
                    aria-labelledby="pills-buying-tab">
                    <div class="row projects-wrapper">
                        @for(data of gujaratiMediumData;track $index){
                        <div class="col-md-4 col-12 mt-4 pt-2">
                            <div class="card border-0 work-container work-classic">
                                <div class="card-body p-0">
                                    <a (click)="openInstituteById(data.type)"><img src="{{data.image}}"
                                            class="img-fluid rounded work-image" alt="institute"></a>
                                    <div class="content pt-3">
                                        <h5 class="mb-0 text-center"><a (click)="openInstituteById(data.type)"
                                                class="text-primary title">{{data.name}}</a>
                                        </h5>
                                        <h6 class="text-muted text-center tag mb-0">{{data.category}}</h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                        }
                    </div>

                </div><!--end teb pane-->

                <!-- <div class="tab-pane fade" id="pills-general" role="tabpanel" aria-labelledby="pills-general-tab">
                        <div class="row projects-wrapper">
                            @for(data of englishMediumData;track $index){
                            <div class="col-md-6 col-12 mt-4 pt-2">
                                <div class="card border-0 work-container work-classic">
                                    <div class="card-body p-0">
                                        <a (click)="openInstituteById(data.type)"><img src="{{data.image}}"
                                                class="img-fluid rounded work-image" alt=""></a>
                                        <div class="content pt-3">
                                            <h5 class="mb-0 text-center"><a (click)="openInstituteById(data.type)"
                                                    class="text-primary title">{{data.name}}</a>
                                            </h5>
                                            <h6 class="text-muted text-center tag mb-0">{{data.category}}</h6>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            }
                        </div>
                    </div> -->

            </div><!--end tab content-->
        </div><!--end col-->
    </div><!--end row-->

    <div class="position-absolute top-50 end-0 translate-middle-y" style="z-index: -1; opacity: 0.1;">
        <img src="assets/images/icon-gradient.png" height="550" alt="">
    </div>
</section><!--end section-->
<!-- End -->
<!-- Shape Start -->
<div class="position-relative">
    <div class="shape overflow-hidden text-color-white">
        <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
        </svg>
    </div>
</div>
<!--Shape End-->

<!-- Team Start -->

<!--end section-->
<!-- Team Start -->
<div class="position-relative">
    <div class="shape overflow-hidden text-footer">
        <svg viewBox="0 0 2880 250" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M720 125L2160 0H2880V250H0V125H720Z" fill="currentColor"></path>
        </svg>
    </div>
</div>
<!-- Back to top -->
<a href="javascript: void(0);" class="btn btn-icon btn-primary back-to-top" id="back-to-top" [ngxScrollTo]="'#home'">
    <i-feather name="arrow-up" class="icons"></i-feather>
</a>
<!-- Back to top -->