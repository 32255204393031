import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-multimedia',
  templateUrl: './multimedia.component.html',
  styleUrl: './multimedia.component.css'
})
export class MultimediaComponent {
  sliderTopbar = false;
  Menuoption = 'center';
  Settingicon = true;
  multiMedia: any;
  multiData: any = {};
  constructor(
    private activatedRoute: ActivatedRoute,

  ) {
    this.activatedRoute.queryParams.subscribe((res: any) => {
      this.multiMedia = res.id;
      if (this.multiMedia == 'Multimedia-Balmandir') {
        this.multiData.name = 'Shri R.R. Dave Multimedia Education Center';
        this.multiData.title = 'Inspiring Creativity, Nurturing Curiosity';
        this.multiData.details = 'At the Shri R.R. Dave Multimedia Education Center for Pre-School Students, our mission is to inspire creativity, nurture curiosity, and lay a strong foundation for digital literacy. We aim to provide a fun and engaging learning environment where young learners can explore the exciting world of multimedia in a playful yet educational manner.'
      }
      else if (this.multiMedia == 'Computer-Education') {
        this.multiData.name = 'Shri Kanchanlal Manilal Shah Chahwala';

      }
      else if (this.multiMedia == 'Sharda-Multimedia') {
        this.multiData.name = 'Shree Sharda Kelavani Mandal Multimedia Education Centre';
      }
    })
  }
}
