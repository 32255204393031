<!-- Hero Start -->
<section class="bg-half-170 bg-light d-table w-100" id="home">
    <div class="container">
        <div class="row mt-5 justify-content-center">
            <div class="col-lg-12 text-center">
                <div class="pages-heading">
                    <h4 class="title mb-0">Staff Details</h4>
                </div>
            </div> <!--end col-->
        </div><!--end row-->

        <div class="position-breadcrumb">
            <nav aria-label="breadcrumb" class="d-inline-block">
                <ul class="breadcrumb rounded shadow mb-0 px-4 py-2">
                    <li class="breadcrumb-item"><a routerLink="/index">Home</a></li>
                    <li class="breadcrumb-item"><a routerLink="/index-job">Staff Details</a></li>
                </ul>
            </nav>
        </div>
    </div> <!--end container-->
</section><!--end section-->
<div class="position-relative">
    <div class="shape overflow-hidden text-color-white">
        <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
        </svg>
    </div>
</div>
<!-- Hero End -->


<section class="section">
    <div class="container">

        <div class="row">
            <div class="row align-items-center">
                <div class="col-lg-9 col-md-8">
                </div><!--end col-->

                <div class="col-lg-3 col-md-4 mt-4 mt-sm-0 pt-2 pt-sm-0">
                    <div class="form custom-form">
                        <div class="mb-0">
                            <select class="form-select form-control" aria-label="Default select example"
                                id="Sortbylist-job" (change)="onChange($event.target.value)">
                                <option selected>Select Department</option>
                                <option value="all">ALL</option>
                                <option *ngFor="let y of departmentData">{{y.department}}</option>
                            </select>
                        </div>
                    </div>
                </div><!--end col-->
            </div><!--end row-->
            <div class="card event-schedule event-primary rounded border mt-4 mb-4" *ngIf="filterData.length==0">
                <div class="card-body text-center">
                    <span class="text-center text-warning">Staff Details are not available.</span>
                </div>
            </div>
            <div class="row" *ngIf="filterData.length>0">
                <div class="col-lg-4 col-md-6 col-12 mt-4 pt-2" *ngFor="let s of filterData;let i=index">
                    <div class="job-box job-primary candidate-list card rounded border-0 shadow">
                        <div class="card-body">
                            <div class="content text-center">
                                <img src="https://api.shreeshardakelavanimandal.ac.in{{s.profile_image}}"
                                    class="avatar avatar-md-md shadow-md rounded-circle" alt="staff profile">
                                <br>
                                <a routerLink="/job-candidate" class="text-dark h5 name">{{s.name}}</a>
                                <p class="text-muted my-1">{{s.designation}}</p>
                                <span class="text-muted">
                                    <i class="uil uil-graduation-cap h4 mb-0 me-2 icons"></i>{{s.qualification}}
                                </span>
                                <br>
                                <span class="text-muted"><i class="uil uil-envelope h4 mb-0 me-2 icons"></i>{{s.email}}
                                </span>
                                <div class="d-grid mt-2" *ngIf="s.researchPaper !='undefined'">
                                    <a href="https://api.shreeshardakelavanimandal.ac.in{{s.researchPaper}}" target="_blank"
                                        class="btn btn-soft-primary">Read More <i
                                            class="uil uil-angle-right-b align-middle"></i></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div><!--end col-->

                <!-- PAGINATION START -->
                <div class="col-12 mt-4 mb-4">
                    <ul class="pagination justify-content-center mb-0">
                        <li class="page-item" [class.disabled]="currentPage === 1">
                            <a class="page-link" href="javascript:void(0)" aria-label="Previous"
                                (click)="prevPage()">Prev</a>
                        </li>
                        <li class="page-item" *ngFor="let page of pages" [class.active]="currentPage === page">
                            <a class="page-link" href="javascript:void(0)" (click)="changePage(page)">{{ page }}</a>
                        </li>
                        <li class="page-item" [class.disabled]="currentPage === totalPages">
                            <a class="page-link" href="javascript:void(0)" aria-label="Next"
                                (click)="nextPage()">Next</a>
                        </li>
                    </ul>
                </div>
                <!-- PAGINATION END -->
            </div><!--end row-->
        </div>
    </div><!--end container-->
</section><!--end section-->


<div class="position-relative">
    <div class="shape overflow-hidden text-footer">
        <svg viewBox="0 0 2880 250" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M720 125L2160 0H2880V250H0V125H720Z" fill="currentColor"></path>
        </svg>
    </div>
</div>
<!-- Back to top -->
<a href="javascript: void(0);" class="btn btn-icon btn-primary back-to-top" id="back-to-top" [ngxScrollTo]="'#home'">
    <i-feather name="arrow-up" class="icons"></i-feather>
</a>
<!-- Back to top -->