<!-- Hero Start -->
<section class="bg-half-170 bg-light d-table w-100" id="home">
    <div class="container">
        <div class="row mt-5 justify-content-center">
            <div class="col-lg-12 text-center">
                <div class="pages-heading">
                    <h4 class="title mb-0"> Our Gallery </h4>
                </div>
            </div> <!--end col-->
        </div><!--end row-->

        <div class="position-breadcrumb">
            <nav aria-label="breadcrumb" class="d-inline-block">
                <ul class="breadcrumb rounded shadow mb-0 px-4 py-2">
                    <li class="breadcrumb-item"><a routerLink="/index">Home</a></li>
                    <li class="breadcrumb-item active" aria-current="page">Gallery</li>
                </ul>
            </nav>
        </div>
    </div> <!--end container-->
</section><!--end section-->
<!-- Hero End -->

<!-- Shape Start -->
<div class="position-relative">
    <div class="shape overflow-hidden text-color-white">
        <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
        </svg>
    </div>
</div>
<!--Shape End-->

<section class="section mb-5">
    <div class="container" id="portfolio">
        <div class="card event-schedule event-primary rounded border" *ngIf="galleryImg.length==0">
            <div class="card-body text-center">
                <span class="text-center text-warning">Images not available.</span>
            </div>
        </div>
        <div class="row" *ngIf="galleryImg.length > 0">
            <div class="col-lg-4 col-md-6 col-12 mt-4 pt-2" *ngFor="let img of paginatedGalleryImg;track $index">
                <div
                    class="card border-0 work-container work-primary work-modern position-relative d-block overflow-hidden rounded">
                    <div class="card-body p-0">
                        <img [src]="'https://api.shreeshardakelavanimandal.ac.in'+img.image" class="img-fluid" alt="work-image">
                        <div class="overlay-work"></div>
                        <div class="icons text-center">
                            <a (click)="open($index)"
                                class="text-primary work-icon bg-white d-inline-block rounded-pill mfp-image">
                                <i-feather name="zoom-in" class="fea icon-sm"></i-feather>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-12 mt-4 pt-2">
                <ul class="pagination justify-content-center mb-0">
                    <li class="page-item" [class.disabled]="currentPage === 1">
                        <a class="page-link" href="javascript:void(0)" aria-label="Previous"
                            (click)="prevPage()">Prev</a>
                    </li>
                    <li class="page-item" *ngFor="let page of pages" [class.active]="currentPage === page">
                        <a class="page-link" href="javascript:void(0)" (click)="changePage(page)">{{ page }}</a>
                    </li>
                    <li class="page-item" [class.disabled]="currentPage === totalPages">
                        <a class="page-link" href="javascript:void(0)" aria-label="Next" (click)="nextPage()">Next</a>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</section>
<div class="position-relative">
    <div class="shape overflow-hidden text-footer">
        <svg viewBox="0 0 2880 250" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M720 125L2160 0H2880V250H0V125H720Z" fill="currentColor"></path>
        </svg>
    </div>
</div>
<!-- Back to top -->
<a href="javascript: void(0);" class="btn btn-icon btn-primary back-to-top" id="back-to-top" [ngxScrollTo]="'#home'">
    <i-feather name="arrow-up" class="icons"></i-feather>
</a>
<!-- Back to top -->