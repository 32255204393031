<!-- Hero Start -->
<section class="bg-half-170 bg-light d-table w-100" id="home">
    <div class="container">
        <div class="row mt-5 justify-content-center">
            <div class="col-lg-12 text-center">
                <div class="pages-heading">
                    <h4 class="title mb-0"> Our Management </h4>
                </div>
            </div> <!--end col-->
        </div><!--end row-->

        <div class="position-breadcrumb">
            <nav aria-label="breadcrumb" class="d-inline-block">
                <ul class="breadcrumb rounded shadow mb-0 px-4 py-2">
                    <li class="breadcrumb-item"><a routerLink="/index">Home</a></li>
                    <li class="breadcrumb-item active" aria-current="page">Management</li>
                </ul>
            </nav>
        </div>
    </div> <!--end container-->
</section><!--end section-->
<!-- Hero End -->

<!-- Shape Start -->
<div class="position-relative">
    <div class="shape overflow-hidden text-color-white">
        <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
        </svg>
    </div>
</div>
<!--Shape End-->

<!-- Team Start -->
<section class="section mb-5">
    <div class="container">
        <div class="row">
            <div class="col-12 text-center">
                <div class="section-title mb-4 pb-2">
                    <h4 class="title">Management members of <span class="text-primary">Shree Sharda Kelavani
                            Mandal</span>.</h4>
                </div>
            </div>
            <!--end col-->
        </div>
        <!--end row-->
        <div class="row">
            @for(data of memberData;track $index){
            <div class="col-lg-4 col-md-6 mt-4 pt-2">
                <div class="card team text-center border-0">
                    <div class="position-relative">
                        <img src="{{data.profile}}" class="img-fluid avatar avatar-ex-large rounded-circle shadow"
                            alt="">
                    </div>
                    <div class="card-body py-3 px-0 content">
                        <h5 class="mb-0"><a href="javascript:void(0)" class="name text-dark">{{data.name}}</a></h5>
                        <small class="designation text-muted">{{data.designation}}</small>
                    </div>
                </div>
            </div>
            }
        </div>
        <!-- <div class="row mt-4">
            <div class="col-12 text-center">
                <div class="section-title mb-4 pb-2">
                    <h4 class="title">Executive Members of <span class="text-primary">Shree Sharda Kelavani
                            Mandal</span>.</h4>
                </div>
            </div>
        </div> -->
    </div>
    <!--end container-->
    <div class="container mb-5">
        <div class="row">
            <!-- Table Start -->
            <div class="col-12">
                <div class="component-wrapper rounded shadow">
                    <div class="p-4 border-bottom">
                        <h5 class="title mb-0"> Office Bearers of <b>Shree L.M Sharda Mandir</b> </h5>
                    </div>

                    <div class="p-4">
                        <div class="table-responsive bg-white shadow rounded">
                            <table class="table mb-0 table-center">
                                <thead>
                                    <tr class="text-center">
                                        <th scope="col" class="border-bottom">#</th>
                                        <th scope="col" class="border-bottom">Name</th>
                                        <th scope="col" class="border-bottom">Designation</th>
                                        <th scope="col" class="border-bottom">Qualification</th>
                                        <th scope="col" class="border-bottom">Tenure</th>

                                    </tr>
                                </thead>
                                <tbody class="text-center">
                                    <tr>
                                        <th scope="row">1</th>
                                        <td>Shri K. K. Patel</td>
                                        <td>Principal</td>
                                        <td>B.A. , B.Ed.</td>
                                        <td>10-06-1963 to 31-12-1967</td>
                                    </tr>
                                    <tr>
                                        <th scope="row">2</th>
                                        <td>Shri M. L. Vyas</td>
                                        <td>E.Principal</td>
                                        <td>B.A. , B.Ed.</td>
                                        <td>01-01-1968 to 31-05-1968</td>
                                    </tr>
                                    <tr>
                                        <th scope="row">3</th>
                                        <td>Shri G. J. Talati</td>
                                        <td>E.Principal</td>
                                        <td>B.A. , B.Ed.</td>
                                        <td>01-06-1968 to 30-06-1969</td>
                                    </tr>
                                    <tr>
                                        <th scope="row">4</th>
                                        <td>Shri R. R. Dave</td>
                                        <td>Principal</td>
                                        <td>B.Sc. , M.Ed.</td>
                                        <td>01-07-1969 to 31-10-1987</td>
                                    </tr>
                                    <tr>
                                        <th scope="row">5</th>
                                        <td>Shri R. B. Joshi</td>
                                        <td>Principal</td>
                                        <td>M.A. , B.Ed.</td>
                                        <td>09-11-1987 to 31-10-1998</td>
                                    </tr>
                                    <tr>
                                        <th scope="row">6</th>
                                        <td>Mrs. K. R. Dave</td>
                                        <td>E.Principal</td>
                                        <td>MA , B.Ed.</td>
                                        <td>01-11-1998 to 31-03-1999</td>
                                    </tr>
                                    <tr>
                                        <th scope="row">7</th>
                                        <td>Shri S. N. Patel</td>
                                        <td>Principal</td>
                                        <td>M.Sc. , M.Ed.</td>
                                        <td>01-04-1999 to 30-10-2023</td>
                                    </tr>
                                    <tr>
                                        <th scope="row">8</th>
                                        <td>Shri L. G. Vankar</td>
                                        <td>E.Principal</td>
                                        <td>B.A. , Bed</td>
                                        <td>31-10-2023</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div><!--end col-->
            <!-- Table End -->
        </div><!--end row-->
    </div>
    <div class="container">
        <div class="row">
            <!-- Table Start -->
            <div class="col-12">
                <div class="component-wrapper rounded shadow">
                    <div class="p-4 border-bottom">
                        <h5 class="title mb-0"> Office Bearers of <b>Shree A.C Sharda Mandir</b> </h5>
                    </div>

                    <div class="p-4">
                        <div class="table-responsive bg-white shadow rounded">
                            <table class="table mb-0 table-center">
                                <thead>
                                    <tr class="text-center">
                                        <th scope="col" class="border-bottom">#</th>
                                        <th scope="col" class="border-bottom">Name</th>
                                        <th scope="col" class="border-bottom">Designation</th>
                                        <th scope="col" class="border-bottom">Qualification</th>
                                        <th scope="col" class="border-bottom">Tenure</th>

                                    </tr>
                                </thead>
                                <tbody class="text-center">
                                    <tr>
                                        <th scope="row">1</th>
                                        <td>Shri Kantilal P. Shah</td>
                                        <td>Principal</td>
                                        <td>-</td>
                                        <td>01-06-1969 to 07-05-1977</td>
                                    </tr>
                                    <tr>
                                        <th scope="row">2</th>
                                        <td>Shri Ambalal M. Trivedi</td>
                                        <td>Principal</td>
                                        <td>-</td>
                                        <td>12-06-1977 to 06-06-1978</td>
                                    </tr>
                                    <tr>
                                        <th scope="row">3</th>
                                        <td>Shri Vadilal D. Shah</td>
                                        <td>Principal</td>
                                        <td>-</td>
                                        <td>14-6-1978 to 05-05-1979</td>
                                    </tr>
                                    <tr>
                                        <th scope="row">4</th>
                                        <td>Shri Narahari C. Pandaya</td>
                                        <td>Principal</td>
                                        <td>B.Sc. , M.Ed.</td>
                                        <td>04-12-1979 to 30-06-1981</td>
                                    </tr>
                                    <tr>
                                        <th scope="row">5</th>
                                        <td>Shri R. R. Dave</td>
                                        <td>E.Principal</td>
                                        <td>M.A. , B.Ed.</td>
                                        <td>01-07-1981 to 20-11-1983</td>
                                    </tr>
                                    <tr>
                                        <th scope="row">6</th>
                                        <td>Shri Mahendrabhai R.Amin</td>
                                        <td>Principal</td>
                                        <td>MA , B.Ed.</td>
                                        <td>21-11-1983 to 28-06-1993</td>
                                    </tr>
                                    <tr>
                                        <th scope="row">7</th>
                                        <td>Mrs. Meenakshiben C. Dave</td>
                                        <td>Principal</td>
                                        <td>S.S.C. , H.S.C.</td>
                                        <td>01-07-1993 to 30-06-2000</td>
                                    </tr>
                                    <tr>
                                        <th scope="row">8</th>
                                        <td>Shri Vinodchandra C.Patel</td>
                                        <td>Principal</td>
                                        <td>S.S.C. , P.T.C.</td>
                                        <td>01-07-2000 to 31-05-2011</td>
                                    </tr>
                                    <tr>
                                        <th scope="row">9</th>
                                        <td>Shri Chetankumar R.Patel</td>
                                        <td>E.Principal</td>
                                        <td>S.S.C. , P.T.C.</td>
                                        <td>01-06-2011</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div><!--end col-->
            <!-- Table End -->
        </div><!--end row-->
    </div>
</section>


<!-- Team Start -->
<div class="position-relative">
    <div class="shape overflow-hidden text-footer">
        <svg viewBox="0 0 2880 250" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M720 125L2160 0H2880V250H0V125H720Z" fill="currentColor"></path>
        </svg>
    </div>
</div>
<!-- Back to top -->
<a href="javascript: void(0);" class="btn btn-icon btn-primary back-to-top" id="back-to-top" [ngxScrollTo]="'#home'">
    <i-feather name="arrow-up" class="icons"></i-feather>
</a>
<!-- Back to top -->