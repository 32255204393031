<!-- Hero Start -->
<section class="bg-half-170 bg-light d-table w-100" id="home">
    <div class="container">
        <div class="row mt-5 justify-content-center">
            <div class="col-lg-12 text-center">
                <div class="pages-heading">
                    <h4 class="title mb-0"> {{bDetails.blogTitle}} </h4>
                </div>
            </div> <!--end col-->
        </div><!--end row-->

        <div class="position-breadcrumb">
            <nav aria-label="breadcrumb" class="d-inline-block">
                <ul class="breadcrumb rounded shadow mb-0 px-4 py-2">
                    <li class="breadcrumb-item"><a routerLink="/index">Home</a></li>
                    <li class="breadcrumb-item"><a routerLink="/blog">Blog</a></li>
                    <li class="breadcrumb-item active" aria-current="page">Blog Details</li>
                </ul>
            </nav>
        </div>
    </div> <!--end container-->
</section><!--end section-->
<!-- Hero End -->

<!-- Shape Start -->
<div class="position-relative">
    <div class="shape overflow-hidden text-color-white">
        <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
        </svg>
    </div>
</div>
<!--Shape End-->

<!-- Blog STart -->
<section class="section">
    <div class="container">
        <div class="row">
            <!-- BLog Start -->
            <div class="col-lg-8 col-md-6">
                <div class="card blog blog-detail border-0 shadow rounded">
                    <img src="https://api.shreeshardakelavanimandal.ac.in{{bDetails.blogImage}}" class="img-fluid rounded-top" alt="">
                    <div class="card-body content">
                        <h6><i class="mdi mdi-calendar text-primary me-1"></i><a href="javscript:void(0)"
                                class="text-primary">{{bDetails.blogDate|date}}</a>, <i
                                class="mdi mdi-account-outline text-primary me-1"></i><a href="javscript:void(0)"
                                class="text-primary">{{bDetails.authorName}}</a></h6>
                        <h3 class="mt-4">{{bDetails.blogTitle}}</h3>
                        <div [innerHtml]='bDetails.blogDetails'></div>
                    </div>
                </div>
            </div>
            <!-- BLog End -->

            <!-- START SIDEBAR -->
            <div class="col-lg-4 col-md-6 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
                <div class="card border-0 sidebar sticky-bar ms-lg-4">
                    <div class="card-body p-0">
                        <!-- RECENT POST -->
                        <div class="widget ">
                            <span class="bg-light d-block py-2 rounded shadow text-center h6 mb-0">
                                Recent Post
                            </span>

                            <div class="mt-4">
                                <div class="d-flex align-items-center">
                                    <img src="assets/images/blog/01.jpg" class="avatar avatar-small rounded"
                                        style="width: auto;" alt="">
                                    <div class="flex-1 ms-3">
                                        <a href="javascript:void(0)" class="d-block title text-dark">Consultant
                                            Business</a>
                                        <span class="text-muted">15th April 2021</span>
                                    </div>
                                </div>

                                <div class="d-flex align-items-center mt-3">
                                    <img src="assets/images/blog/02.jpg" class="avatar avatar-small rounded"
                                        style="width: auto;" alt="">
                                    <div class="flex-1 ms-3">
                                        <a href="javascript:void(0)" class="d-block title text-dark">Grow Your
                                            Business</a>
                                        <span class="text-muted">15th April 2021</span>
                                    </div>
                                </div>

                                <div class="d-flex align-items-center mt-3">
                                    <img src="assets/images/blog/03.jpg" class="avatar avatar-small rounded"
                                        style="width: auto;" alt="">
                                    <div class="flex-1 ms-3">
                                        <a href="javascript:void(0)" class="d-block title text-dark">Look On The
                                            Glorious Balance</a>
                                        <span class="text-muted">15th April 2021</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- RECENT POST -->

                        <!-- SOCIAL -->
                        <div class="widget mt-4">
                            <span class="bg-light d-block py-2 rounded shadow text-center h6 mb-0">
                                Social Media
                            </span>

                            <ul class="list-unstyled social-icon social text-center mb-0 mt-4">
                                <li class="list-inline-item ms-1"><a href="javascript:void(0)"
                                        class="rounded"><i-feather name="facebook"
                                            class="fea icon-sm fea-social"></i-feather></a></li>
                                <li class="list-inline-item ms-1"><a href="javascript:void(0)"
                                        class="rounded"><i-feather name="instagram"
                                            class="fea icon-sm fea-social"></i-feather></a></li>
                                <li class="list-inline-item ms-1"><a href="javascript:void(0)"
                                        class="rounded"><i-feather name="twitter"
                                            class="fea icon-sm fea-social"></i-feather></a></li>
                                <li class="list-inline-item ms-1"><a href="javascript:void(0)"
                                        class="rounded"><i-feather name="linkedin"
                                            class="fea icon-sm fea-social"></i-feather></a></li>
                            </ul><!--end icon-->
                        </div>
                        <!-- SOCIAL -->
                    </div>
                </div>
            </div><!--end col-->
            <!-- END SIDEBAR -->
        </div>
        <!--end row-->
    </div>
    <!--end container-->
</section>
<!--end section-->
<!-- Blog End -->
<div class="position-relative">
    <div class="shape overflow-hidden text-footer">
        <svg viewBox="0 0 2880 250" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M720 125L2160 0H2880V250H0V125H720Z" fill="currentColor"></path>
        </svg>
    </div>
</div>
<!-- Back to top -->
<a href="javascript: void(0);" class="btn btn-icon btn-primary back-to-top" id="back-to-top" [ngxScrollTo]="'#home'">
    <i-feather name="arrow-up" class="icons"></i-feather>
</a>
<!-- Back to top -->