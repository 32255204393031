import { Component, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-result',
  templateUrl: './result.component.html',
  styleUrl: './result.component.css',
  encapsulation: ViewEncapsulation.None // Disable encapsulation

})
export class ResultComponent {
  sliderTopbar = false;
  Menuoption = 'center';
  Settingicon = true;

  mainData: any = [];

}
